import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  Box,
  IconButton,
  useMediaQuery,
  Theme,
  Tooltip,
} from "@mui/material";
import { useRedux } from "../../hooks";
import { changeTab } from "../../redux/actions";
import { TABS } from "../../constants";
import logo from "../../assets/images/logo-icon-verde.svg";
import { useTranslation } from "react-i18next";
import ProfileDropdownMenu from "../../components/ProfileDropdownMenu";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "../../assets/images/new-icons/home-icon.svg";
import messageIcon from "../../assets/images/new-icons/message-icon.svg";
import walletIcon from "../../assets/images/new-icons/cards-icon.svg";
import settingsIcon from "../../assets/images/new-icons/settings-icon.svg";
import { getMenuItems } from "./menu";
import FloatingSupportIcon from "../../components/FloatingSupportIcon";

const Logo = ({ to, isMobile }: { to: string; isMobile: boolean }) => (
  <Link to={to}>
    <IconButton>
      {isMobile ? (
        <img src={HomeIcon} alt="Home Icon" width="34px" />
      ) : (
        <img src={logo} width="34px" alt="Logo" />
      )}
    </IconButton>
  </Link>
);

interface SideMenuProps {
  onChangeLayoutMode: (value: any) => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ onChangeLayoutMode }) => {
  const { dispatch, useAppSelector } = useRedux();
  const { t } = useTranslation();
  const menuItems = getMenuItems(t);
  const { activeTab } = useAppSelector(state => ({
    activeTab: state.Layout.activeTab,
  }));

  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<TABS>(TABS.USERS);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );

  useEffect(() => {
    setSelectedTab(activeTab);
  }, [activeTab]);

  const onChangeTab = (id: TABS) => {
    setSelectedTab(id);
    dispatch(changeTab(id));
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <Box
      sx={{
        minWidth: { xs: "100%", md: "4.6875rem" },
        minHeight: { xs: "auto", md: "100vh" },
        maxWidth: { md: "4.6875rem" },
        height: "auto",
        backgroundColor: "#36276F",
        display: "flex",
        flexDirection: { xs: "row", md: "column" },
        zIndex: 9,
        borderRight: { md: "1px solid #000" },
        position: { xs: "fixed", md: "relative" },
        bottom: { xs: 0, md: "auto" },
        borderTop: { xs: "1px solid #000", md: "none" },
        alignItems: "center",
        justifyContent: isMobile ? "space-around" : "flex-end",
        padding: isMobile ? "1.5rem" : "1.25rem",
      }}
    >
      {/* Logo */}
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "2rem",
          }}
        >
          <Logo to="/" isMobile={isMobile} />
        </Box>
      )}
      {/* Main icons at the center*/}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: { xs: "row", md: "column" },
          justifyContent: "end",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {/* Support */}
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            marginX: "auto",
            borderRadius: "0.5rem",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: "#4C3699",
            },
          }}
        >
          <FloatingSupportIcon />
        </Box>
        {/* Chat */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tooltip title={t("Chat")} placement="right" arrow>
            <ListItem
              component={Link}
              to="/chat"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                padding: "0.25rem",
                marginX: "auto",
                borderRadius: "0.5rem",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#4C3699",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: "white",
                  fontSize: 24,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={messageIcon} alt="Chat Icon" width="34px" />{" "}
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        </Box>
        {/* Payments */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tooltip title={t("Wallet")} placement="right" arrow>
            <ListItem
              component={Link}
              to="/subscription"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                padding: "0.25rem",
                marginX: "auto",
                borderRadius: "0.5rem",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#4C3699",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: "white",
                  fontSize: 24,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={walletIcon} alt="Wallet Icon" width="34px" />{" "}
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        </Box>
        {/* settings */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tooltip title={t("Settings")} placement="right" arrow>
            <ListItem
              component={Link}
              to="/settings"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                padding: "0.25rem",
                marginX: "auto",
                borderRadius: "0.5rem",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#4C3699",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: "white",
                  fontSize: 24,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={settingsIcon} alt="Settings Icon" width="34px" />{" "}
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        </Box>
        {/* User Profile*/}
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            marginX: "auto",
            borderRadius: "0.5rem",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: "#4C3699",
            },
          }}
        >
          <ProfileDropdownMenu />
        </Box>
      </Box>
      {/* Home icon */}
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "2rem",
          }}
        >
          <Tooltip title={t("Home")} placement="right" arrow>
            <ListItem
              component={Link}
              to="/dashboard"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                padding: "0.25rem",
                marginX: "auto",
                borderRadius: "0.5rem",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#4C3699",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: "white",
                  fontSize: "1.5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={HomeIcon} alt="Home Icon" width="34px" />{" "}
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default SideMenu;
