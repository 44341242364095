import React, { useEffect } from "react";
// @ts-ignore
import { gsap } from 'gsap';

const WaveDashboard = () => {

  useEffect(() => {
    const svgs = document.querySelectorAll(".animatable-svg");
    svgs.forEach((svg) => {
      const paths = svg.querySelectorAll("path");

      const colors = ["#92EF8F", "#fff"];

      paths.forEach((path, i) => {
        const pathLength = path.getTotalLength();

        gsap.set(path, {
          strokeDasharray: pathLength,
          strokeDashoffset: pathLength,
          stroke: colors[i % colors.length],
        });

        gsap.to(path, {
          strokeDashoffset: 0,
          duration: 2 + i * 0.2,
          repeat: -1,
          yoyo: true,
          ease: "power2.inOut",
        });

        gsap.fromTo(
          path,
          { scale: 1 },
          {
            scale: 1.05,
            duration: 2,
            repeat: -1,
            yoyo: true,
            transformOrigin: "center",
            ease: "sine.inOut",
          }
        );
      });
    });
  }, []);

  return (
    <svg className="animatable-svg lines-svg" width="3000" height="1000" viewBox="0 0 1188 978" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_109_2312)">
        <path opacity="0.03" d="M68.5622 651.433C113.132 620.97 158.325 590.187 209.158 572.038C259.989 553.878 317.945 549.483 367.076 571.655C421.795 596.479 458.336 650.13 511.373 677.857C570.815 709.122 642.962 703.229 708.689 686.653C764.703 672.525 827.017 641.098 836.137 584.226C842.411 545.092 820.802 510.094 807.178 474.478C793.995 440.023 793.295 390.891 826.144 365.075C851.72 344.965 887.549 350.729 919.562 353.126C1038.43 362.064 1159.86 297.122 1219.07 193.357C1243.63 150.301 1260.6 101.941 1300.3 71.6808C1377.21 13.04 1492.63 64.9497 1582.01 27.8459" stroke="url(#paint0_linear_109_2312)" stroke-width="0.57" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.06" d="M72.0949 660.349C115.406 629.039 159.341 597.41 209.169 578.071C258.998 558.742 316.205 552.783 365.458 573.149C420.203 596.031 457.92 647.404 510.864 673.149C570.215 702.006 640.707 694.177 705.637 676.76C760.327 662.094 820.941 631.115 831.08 575.776C838.111 537.369 818.005 504.752 806.167 470.741C795.134 439.026 798.673 390.553 832.467 363.46C857.828 343.125 893.069 347.705 924.659 349.578C1040.7 356.462 1158.39 293.154 1217.79 192.665C1242.9 150.193 1261.54 104.765 1301.62 74.8599C1377.93 17.9212 1490.68 66.8516 1578.64 30.043" stroke="url(#paint1_linear_109_2312)" stroke-width="0.64" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.08" d="M75.6379 669.263C117.701 637.105 160.367 604.621 209.192 584.113C258.018 563.604 314.534 555.959 363.862 574.649C418.696 595.434 457.479 644.754 510.379 668.458C569.582 694.987 638.477 685.141 702.618 666.882C755.978 651.688 814.918 621.132 826.059 567.351C833.865 529.656 815.161 499.429 805.201 467.026C796.283 438.027 804.108 390.246 838.835 361.868C863.983 341.318 898.627 344.714 929.811 346.051C1043 350.919 1157.04 289.216 1216.57 192.006C1242.23 150.103 1262.51 107.632 1303 78.0712C1378.69 22.8069 1488.78 68.7758 1575.31 32.2838" stroke="url(#paint2_linear_109_2312)" stroke-width="0.71" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.11" d="M79.1811 678.178C119.998 645.181 161.392 611.832 209.206 590.156C257.027 568.468 312.81 559.236 362.249 576.162C417.131 594.961 457.019 642.117 509.874 663.77C568.93 687.971 636.209 676.122 699.579 657.007C751.602 641.308 808.892 611.14 821.019 558.929C829.607 521.935 812.204 494.107 804.214 463.305C797.37 436.969 809.514 389.944 845.182 360.27C870.129 339.522 904.143 341.72 934.932 342.519C1045.23 345.405 1155.7 285.284 1215.32 191.342C1241.55 149.996 1263.44 110.488 1304.34 81.2781C1379.38 27.6838 1486.85 70.7036 1571.95 34.5086" stroke="url(#paint3_linear_109_2312)" stroke-width="0.78" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.14" d="M82.7234 687.092C122.283 653.248 162.409 619.055 209.226 596.187C256.046 573.33 311.092 562.492 360.641 577.654C415.559 594.458 456.565 639.459 509.377 659.071C568.275 680.946 633.942 667.114 696.538 647.123C747.227 630.937 802.902 601.121 815.974 550.488C825.36 514.171 809.124 488.725 803.214 459.576C798.401 435.829 814.919 389.642 851.518 358.674C876.268 337.739 909.638 338.72 940.042 338.99C1047.43 339.939 1154.43 281.36 1214.05 190.67C1240.87 149.867 1264.35 113.325 1305.67 84.4752C1380.07 32.5214 1484.92 72.6235 1568.59 36.7335" stroke="url(#paint4_linear_109_2312)" stroke-width="0.85" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.17" d="M86.257 696.008C124.568 661.316 163.425 626.268 209.24 602.231C255.055 578.194 309.359 565.771 359.027 579.167C413.984 593.986 456.096 636.824 508.863 654.385C567.604 673.935 631.649 658.12 693.49 637.25C742.84 620.6 796.922 591.1 810.934 542.066C821.125 506.405 805.907 483.309 802.229 455.864C799.385 434.596 820.331 389.37 857.867 357.085C882.419 335.963 915.137 335.74 945.175 335.466C1049.63 334.515 1153.24 277.463 1212.81 190.014C1240.23 149.752 1265.28 116.18 1307.03 87.6901C1380.73 37.3723 1482.99 74.5611 1565.25 38.9663" stroke="url(#paint5_linear_109_2312)" stroke-width="0.92" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.19" d="M89.7994 704.923C126.862 669.382 164.449 633.479 209.263 608.272C254.066 583.067 307.632 569.038 357.431 580.667C412.417 593.503 455.634 634.178 508.377 649.694C566.951 666.92 629.368 649.144 690.471 627.372C738.482 610.267 791.001 581.068 805.912 533.641C816.924 498.612 807.024 477.157 801.253 452.151C803.681 433.318 825.771 389.092 864.224 355.495C888.592 334.203 920.635 332.76 950.307 331.942C1051.79 329.137 1152.12 273.583 1211.56 189.349C1239.61 149.602 1266.2 119.028 1308.38 90.8952C1381.39 42.1957 1481.07 76.4791 1561.89 41.201" stroke="url(#paint6_linear_109_2312)" stroke-width="0.99" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.22" d="M93.3424 713.837C129.148 677.449 165.476 640.7 209.274 614.305C253.074 587.921 305.887 572.309 355.814 582.16C410.828 593.013 455.153 631.535 507.869 644.987C566.265 659.89 627.066 640.162 687.421 617.489C734.088 599.951 785.086 571.015 800.86 525.211C812.712 490.811 804.652 471.685 800.256 448.432C805.221 432.226 831.184 388.83 870.561 353.899C894.754 332.435 926.094 329.788 955.428 328.411C1053.92 323.807 1151.05 269.715 1210.32 188.683C1239 149.46 1267.11 121.865 1309.73 94.1002C1382.02 46.9911 1479.15 78.4051 1558.55 43.4238" stroke="url(#paint7_linear_109_2312)" stroke-width="1.06" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.25" d="M96.8849 722.751C131.442 685.515 166.501 647.911 209.297 620.347C252.092 592.783 304.155 575.598 354.209 583.672C409.244 592.553 454.673 628.902 507.375 640.307C565.587 652.9 624.763 631.231 684.393 607.622C729.72 589.671 779.224 560.972 795.829 516.788C808.527 482.995 802.302 466.229 799.278 444.709C806.789 431.129 836.628 388.572 876.917 352.299C900.929 330.685 931.551 326.805 960.558 324.877C1056.04 318.53 1150.06 265.873 1209.07 188.007C1238.42 149.293 1268.01 124.685 1311.07 97.2955C1382.65 51.769 1477.23 80.3232 1555.2 45.6469" stroke="url(#paint8_linear_109_2312)" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.28" d="M100.418 731.667C133.717 693.585 167.516 655.123 209.31 626.39C251.103 597.657 302.411 578.879 352.594 585.175C407.649 592.085 454.174 626.273 506.868 635.61C564.895 645.892 622.44 622.304 681.353 597.738C725.343 579.393 773.396 550.892 790.786 508.356C804.367 475.143 799.939 460.755 798.281 440.99C808.339 430.035 842.062 388.316 883.255 350.712C907.107 328.945 936.982 323.848 965.671 321.357C1058.12 313.331 1149.11 262.062 1207.81 187.355C1237.83 149.137 1268.89 127.529 1312.41 100.514C1383.23 56.544 1475.29 82.2644 1551.84 47.8833" stroke="url(#paint9_linear_109_2312)" stroke-width="1.19" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.31" d="M103.961 740.582C136.012 701.65 168.542 662.334 209.333 632.431C250.123 602.519 300.669 582.169 351 586.685C406.076 591.623 453.699 623.66 506.385 630.928C564.218 638.912 620.133 613.405 678.326 587.871C720.975 569.164 767.624 540.843 785.757 499.942C800.231 467.308 797.592 455.31 797.307 437.287C809.902 428.96 847.522 388.085 889.616 349.132C913.296 327.213 942.402 315.02 970.816 317.842C1060.28 326.754 1148.28 258.282 1206.58 186.697C1237.31 148.981 1269.79 130.359 1313.77 103.727C1383.82 61.2976 1473.38 84.1984 1548.49 50.126" stroke="url(#paint10_linear_109_2312)" stroke-width="1.26" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.33" d="M107.504 749.496C138.309 709.726 169.569 669.555 209.345 638.465C249.132 607.383 298.916 585.452 349.383 588.179C404.471 591.157 453.188 621.022 505.877 626.221C563.517 631.906 617.805 604.499 675.284 577.977C716.594 558.917 761.866 530.75 780.721 491.489C796.114 459.418 795.237 449.824 796.316 433.546C811.457 427.845 852.966 387.828 895.959 347.514C919.48 325.451 947.73 311.562 975.933 314.29C1062.55 322.692 1147.47 254.496 1205.32 186.014C1236.76 148.786 1270.66 133.164 1315.11 106.914C1384.38 66.0155 1471.45 86.0986 1545.13 52.3311" stroke="url(#paint11_linear_109_2312)" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.36" d="M111.047 758.411C140.593 717.794 170.594 676.766 209.368 644.506C248.151 612.245 297.176 588.753 347.778 589.69C402.879 590.709 452.694 618.423 505.382 621.532C562.82 624.929 615.474 595.635 672.255 568.1C712.231 548.717 756.182 520.684 775.69 483.066C792.032 451.552 792.885 444.359 795.338 429.823C813.016 426.75 858.435 387.595 902.314 345.914C925.678 323.718 953.044 308.138 981.073 310.755C1064.86 318.581 1146.76 250.765 1204.08 185.336C1236.27 148.603 1271.55 135.956 1316.47 110.106C1384.95 70.7119 1469.54 88.0129 1541.81 54.5505" stroke="url(#paint12_linear_109_2312)" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.39" d="M114.579 767.327C142.878 725.861 171.599 683.981 209.381 650.549C247.15 617.11 295.426 592.055 346.172 591.192C401.288 590.261 452.189 615.815 504.877 616.844C562.104 617.956 613.148 586.8 669.218 558.235C707.854 538.551 750.523 510.634 770.651 474.654C787.972 443.692 790.518 438.916 794.354 426.121C814.579 425.674 863.91 387.393 908.664 344.335C931.881 322.003 958.327 304.769 986.197 307.243C1067.19 314.428 1146.09 247.086 1202.83 184.692C1235.79 148.457 1272.41 138.773 1317.82 113.333C1385.49 75.4235 1467.61 89.9622 1538.44 56.7968" stroke="url(#paint13_linear_109_2312)" stroke-width="1.47" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.42" d="M118.123 776.241C145.173 733.927 172.626 691.202 209.392 656.583C246.16 621.974 293.665 595.349 344.555 592.685C399.685 589.804 451.674 613.209 504.368 612.137C561.389 610.984 610.801 577.959 666.166 548.343C703.476 528.375 744.901 500.567 765.595 466.205C783.913 435.781 788.142 433.425 793.344 422.384C816.105 424.564 869.36 387.164 914.99 342.731C938.048 320.265 963.552 301.412 991.307 303.703C1069.51 310.162 1145.48 243.417 1201.57 184.009C1235.32 148.269 1273.25 141.552 1319.15 116.52C1386 80.0797 1465.68 91.8704 1535.08 59.0019" stroke="url(#paint14_linear_109_2312)" stroke-width="1.54" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.44" d="M121.665 785.155C147.458 741.995 173.652 698.413 209.415 662.624C245.179 626.836 291.926 598.66 342.951 594.197C398.086 589.368 451.174 610.631 503.875 607.457C560.688 604.039 608.486 569.183 663.139 538.476C699.128 518.255 739.356 490.526 760.566 457.791C779.924 427.918 785.794 427.979 792.37 418.681C817.678 423.487 874.858 386.978 921.35 341.151C944.263 318.559 968.778 298.116 996.441 300.19C1071.89 305.856 1144.96 239.803 1200.33 183.363C1234.89 148.124 1274.1 144.35 1320.51 119.745C1386.51 84.7459 1463.76 93.818 1531.74 61.2545" stroke="url(#paint15_linear_109_2312)" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.47" d="M125.208 794.07C149.752 750.06 174.676 705.624 209.438 668.666C244.198 631.698 290.178 601.972 341.345 595.699C396.496 588.929 450.662 608.045 503.378 602.758C559.985 597.084 606.171 560.407 660.108 528.59C694.789 508.132 733.841 480.49 755.533 449.357C775.962 420.04 783.441 422.503 791.392 414.958C819.236 422.391 880.352 386.772 927.705 339.551C950.484 316.841 973.967 294.847 1001.57 296.656C1074.28 301.436 1144.49 236.23 1199.09 182.687C1234.5 147.963 1274.94 147.109 1321.86 122.94C1387.01 89.3724 1461.83 95.7261 1528.39 63.4676" stroke="url(#paint16_linear_109_2312)" stroke-width="1.68" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.5" d="M128.742 802.986C152.038 758.128 175.694 712.846 209.45 674.699C243.208 636.562 288.421 605.286 339.74 597.2C394.899 588.502 450.163 605.467 502.884 598.068C559.278 590.15 603.864 551.671 657.07 518.715C690.438 498.043 728.372 470.476 750.502 440.934C772.633 411.392 781.092 417.047 790.405 411.237C820.798 421.306 885.85 386.575 934.052 337.952C956.686 315.117 979.116 291.626 1006.7 293.123C1076.69 296.939 1144.09 232.686 1197.84 182.021C1234.12 147.83 1275.78 149.869 1323.21 126.145C1387.5 93.9812 1459.91 97.6539 1525.04 65.7022" stroke="url(#paint17_linear_109_2312)" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.53" d="M132.284 811.9C154.324 766.205 176.719 720.057 209.463 680.742C242.216 641.425 286.483 607.583 338.124 598.704C393.506 589.179 449.369 601.2 502.376 593.371C558.864 585.027 601.55 542.956 654.028 508.831C686.108 487.97 722.935 460.476 745.457 432.493C767.979 404.509 778.728 411.573 789.415 407.506C822.355 420.201 891.345 386.379 940.396 336.344C962.896 313.391 984.241 288.45 1011.82 289.581C1079.11 292.341 1143.73 229.184 1196.58 181.338C1233.76 147.692 1276.6 152.603 1324.54 129.332C1387.97 98.5641 1457.98 99.562 1521.68 67.9073" stroke="url(#paint18_linear_109_2312)" stroke-width="1.82" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.56" d="M135.827 820.815C156.619 774.271 177.744 727.268 209.486 686.784C241.227 646.299 284.93 611.94 336.518 600.205C391.715 587.657 449.139 600.345 501.881 588.681C557.862 576.293 599.265 534.287 651 498.954C681.785 477.926 717.558 450.517 740.426 424.069C764.267 396.493 776.377 406.108 788.429 403.795C823.906 419.117 896.857 386.21 946.746 334.766C969.114 311.705 992.726 299.341 1016.95 286.067C1080.85 276.388 1143.44 225.751 1195.34 180.692C1233.45 147.607 1277.42 155.354 1325.91 132.555C1388.44 103.155 1456.07 101.508 1518.35 70.158" stroke="url(#paint19_linear_109_2312)" stroke-width="1.89" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.58" d="M139.368 829.719C158.912 782.327 178.769 734.479 209.505 692.806C240.243 651.141 283.182 615.252 334.92 601.695C390.125 587.219 448.651 597.775 501.393 583.97C557.175 569.366 597.013 525.622 647.969 489.068C677.483 467.889 712.214 440.571 735.394 415.636C760.442 388.691 774.026 400.642 787.452 400.072C825.475 418.02 902.387 386.028 953.103 333.166C975.336 309.987 998.273 296.617 1022.09 282.534C1083.09 271.822 1143.2 222.349 1194.11 180.015C1233.17 147.527 1278.24 158.057 1327.26 135.748C1388.91 107.697 1454.15 103.414 1515.01 72.3694" stroke="url(#paint20_linear_109_2312)" stroke-width="1.96" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.61" d="M142.901 838.635C161.187 790.397 179.774 741.694 209.518 698.849C239.252 656.005 281.439 618.594 333.304 603.199C388.531 586.811 448.148 595.238 500.878 579.285C556.462 562.464 594.75 517.02 644.92 479.195C673.178 457.893 706.876 430.665 730.341 407.206C756.634 380.927 771.643 395.172 786.453 396.343C827.012 416.918 907.899 385.86 959.438 331.56C981.549 308.271 1003.8 293.887 1027.19 278.994C1085.31 267.248 1142.99 219.004 1192.84 179.343C1232.9 147.476 1279.03 160.755 1328.59 138.947C1389.34 112.236 1452.22 105.334 1511.63 74.5978" stroke="url(#paint21_linear_109_2312)" stroke-width="2.03" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.64" d="M146.443 847.55C163.482 798.462 180.799 748.905 209.531 704.892C238.263 660.879 279.697 621.936 331.69 604.712C386.939 586.414 447.657 592.709 500.373 574.597C555.77 555.568 592.523 508.442 641.882 469.32C668.887 447.915 701.576 420.793 725.3 398.784C752.875 373.205 769.95 387.219 785.457 392.634C831.662 408.774 913.424 385.709 965.776 329.973C987.755 306.576 1009.33 291.177 1032.32 275.473C1087.54 262.685 1142.83 215.711 1191.59 178.689C1232.67 147.477 1279.82 163.443 1329.94 142.174C1389.77 116.773 1450.29 107.283 1508.28 76.8423" stroke="url(#paint22_linear_109_2312)" stroke-width="2.1" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.67" d="M149.987 856.464C165.777 806.528 181.825 756.116 209.555 710.933C237.283 665.741 277.965 625.276 330.085 606.214C385.365 586.004 447.165 590.171 499.876 569.898C555.089 548.67 590.334 499.898 638.842 459.436C664.617 437.933 696.301 410.947 720.268 390.351C749.155 365.527 767.666 382.056 784.48 388.911C833.039 408.722 918.969 385.555 972.132 328.373C993.989 304.866 1014.87 288.453 1037.45 271.939C1089.78 258.119 1142.72 212.468 1190.34 178.013C1232.49 147.511 1280.62 166.098 1331.28 145.369C1390.2 121.261 1448.36 109.201 1504.93 79.0653" stroke="url(#paint23_linear_109_2312)" stroke-width="2.17" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.69" d="M153.519 865.38C168.052 814.598 182.842 763.339 209.566 716.967C236.292 670.605 276.22 628.608 328.477 607.706C383.779 585.585 446.682 587.63 499.379 565.199C554.407 541.773 588.188 491.376 635.811 449.549C660.38 427.975 691.043 401.138 715.233 381.908C745.461 357.884 765.342 376.851 783.49 385.18C834.354 408.508 924.522 385.389 978.479 326.775C1000.21 303.158 1020.4 285.733 1042.58 268.406C1092.02 253.553 1142.65 209.281 1189.1 177.347C1232.33 147.612 1281.4 168.745 1332.63 148.574C1390.64 125.737 1446.44 111.129 1501.58 81.2902" stroke="url(#paint24_linear_109_2312)" stroke-width="2.24" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.72" d="M157.062 874.295C170.346 822.663 183.867 770.55 209.589 723.008C235.31 675.467 274.499 631.956 326.873 609.217C382.208 585.194 446.215 585.117 498.884 560.509C551.552 535.901 586.073 482.91 632.781 439.673C656.157 418.035 685.804 391.377 710.202 373.484C741.792 350.319 762.978 371.642 782.514 381.467C835.661 408.194 930.092 385.26 984.836 325.185C1006.45 301.468 1025.95 283.019 1047.71 264.882C1094.27 248.996 1142.61 206.158 1187.85 176.682C1233.09 147.205 1282.18 171.374 1333.98 151.779C1391.06 130.196 1444.52 113.045 1498.23 83.523" stroke="url(#paint25_linear_109_2312)" stroke-width="2.31" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.75" d="M160.605 883.209C172.643 830.739 184.892 777.761 209.602 729.051C234.31 680.332 272.768 635.306 325.258 610.721C380.625 584.785 445.736 582.596 498.379 555.821C553.07 528.003 584.006 474.476 629.733 429.8C651.929 408.116 680.553 381.659 705.152 365.064C738.126 342.824 760.579 366.41 781.517 377.747C836.948 407.771 935.641 385.125 991.173 323.588C1012.67 299.76 1031.47 280.299 1052.83 261.35C1096.5 244.432 1142.58 203.085 1186.61 176.016C1232.14 148.014 1282.96 173.972 1335.34 154.982C1391.49 134.633 1442.61 114.971 1494.9 85.7441" stroke="url(#paint26_linear_109_2312)" stroke-width="2.37" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.78" d="M164.148 892.123C174.928 838.807 185.919 784.982 209.623 735.083C233.329 685.194 271.045 638.644 323.662 612.22C379.073 584.39 445.287 580.07 497.892 551.12C552.419 521.11 582.005 466.07 626.714 419.922C647.761 398.197 675.317 371.969 700.131 356.639C734.502 335.393 758.185 361.156 780.541 374.034C838.251 407.274 941.223 385.005 997.531 321.998C1018.91 298.07 1033.97 273.738 1057.96 257.827C1095.1 233.201 1142.58 200.087 1185.36 175.35C1232.1 148.328 1283.73 176.55 1336.69 158.188C1391.91 139.05 1440.68 116.899 1491.55 87.9769" stroke="url(#paint27_linear_109_2312)" stroke-width="2.44" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.81" d="M167.681 901.039C177.213 846.874 186.934 792.195 209.636 741.126C232.338 690.058 269.316 642.003 322.046 613.724C377.491 583.991 444.819 577.557 497.387 546.433C551.753 514.24 580.042 457.708 623.675 410.047C643.586 388.299 670.068 362.321 695.088 348.207C730.87 328.024 755.756 355.878 779.554 370.313C839.542 406.707 946.803 384.875 1003.88 320.41C1025.15 296.38 1039.53 271.317 1063.09 254.305C1097.45 229.492 1142.57 197.142 1184.11 174.696C1232.09 148.768 1284.48 179.112 1338.04 161.404C1392.31 143.461 1438.76 118.837 1488.19 90.2134" stroke="url(#paint28_linear_109_2312)" stroke-width="2.51" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.83" d="M171.224 909.954C179.508 854.94 187.95 799.407 209.65 747.169C231.348 694.922 267.596 645.351 320.431 615.227C375.919 583.59 444.362 575.042 496.881 541.735C551.093 507.348 578.144 449.365 620.625 400.164C639.431 378.387 664.785 352.701 690.046 339.776C727.243 320.736 753.317 350.592 778.557 366.594C840.842 406.078 952.385 384.754 1010.22 318.813C1031.38 294.682 1045.16 268.946 1068.21 250.773C1099.87 225.81 1142.56 194.248 1182.85 174.022C1232.11 149.295 1285.23 181.623 1339.38 164.601C1392.72 147.83 1436.83 120.756 1484.83 92.4283" stroke="url(#paint29_linear_109_2312)" stroke-width="2.58" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.86" d="M174.767 918.868C181.793 863.008 188.977 806.628 209.671 753.201C230.367 699.784 265.884 648.697 318.825 616.729C374.368 583.196 443.915 572.526 496.376 537.048C548.837 501.569 576.324 441.058 617.586 390.29C635.308 368.49 659.5 343.132 685.005 331.354C723.618 313.52 750.875 345.296 777.571 362.883C842.145 405.418 957.98 384.651 1016.56 317.225C1037.61 292.992 1050.88 266.647 1073.33 247.251C1102.39 222.15 1142.55 191.427 1181.6 173.368C1232.16 149.978 1285.98 184.124 1340.72 167.818C1393.13 152.208 1434.89 122.686 1481.48 94.6728" stroke="url(#paint30_linear_109_2312)" stroke-width="2.65" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.89" d="M178.31 927.783C184.088 871.073 190.002 813.839 209.694 759.242C229.386 704.646 264.175 652.054 317.229 618.229C370.283 584.404 443.487 570.006 495.889 532.347C548.291 494.687 574.589 432.766 614.566 380.401C631.224 358.585 654.197 333.576 679.98 322.909C719.993 306.354 748.447 339.967 776.6 359.138C843.478 404.691 963.602 384.524 1022.93 315.603C1043.87 291.278 1056.72 264.38 1078.48 243.705C1105.04 218.467 1142.54 188.645 1180.37 172.679C1232.26 150.785 1286.75 186.55 1342.08 171C1393.57 156.521 1432.98 124.591 1478.15 96.8725" stroke="url(#paint31_linear_109_2312)" stroke-width="2.72" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.92" d="M181.842 936.699C186.372 879.141 191.017 821.052 209.707 765.286C228.397 709.519 262.453 655.402 315.614 619.742C371.256 582.408 443.04 567.499 495.385 527.669C549.183 486.722 572.922 424.522 611.528 370.537C627.151 348.689 648.863 324.077 674.94 314.497C716.336 299.295 746.004 334.671 775.605 355.439C844.791 403.978 969.212 384.449 1029.27 314.027C1050.1 289.6 1062.65 262.206 1083.59 240.194C1107.75 214.802 1142.46 185.959 1179.12 172.035C1232.36 151.807 1287.5 189 1343.43 174.226C1393.99 160.868 1431.06 126.538 1474.78 99.1286" stroke="url(#paint32_linear_109_2312)" stroke-width="2.79" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.94" d="M185.385 945.613C188.657 887.209 192.042 828.263 209.718 771.319C227.395 714.375 260.799 658.829 313.997 621.236C369.748 582.077 442.601 564.971 494.877 522.962C547.152 480.952 571.346 416.261 608.476 360.644C623.086 338.77 643.47 314.579 669.886 306.058C712.645 292.264 743.534 329.329 774.606 351.71C846.114 403.202 974.827 384.343 1035.6 312.43C1056.33 287.91 1068.71 260.059 1088.71 236.663C1110.58 211.074 1142.34 183.321 1177.86 171.361C1232.47 152.98 1288.24 191.371 1344.77 177.423C1394.41 165.173 1429.13 128.458 1471.43 101.344" stroke="url(#paint33_linear_109_2312)" stroke-width="2.86" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.97" d="M188.928 954.527C190.953 895.284 193.069 835.484 209.741 777.361C226.413 719.237 259.07 662.138 312.403 622.746C368.191 581.653 442.237 562.52 494.391 518.27C547.988 473.008 569.867 408.034 605.459 350.776C619.081 328.851 638.084 305.121 664.875 297.631C708.965 285.302 741.106 324 773.65 347.993C847.477 402.418 980.619 384.366 1041.98 310.837C1062.65 286.256 1074.96 257.939 1093.87 233.136C1112.77 208.332 1142.17 180.751 1176.64 170.702C1232.59 154.383 1289.01 193.725 1346.15 180.633C1394.88 169.459 1427.23 130.38 1468.11 103.581" stroke="url(#paint34_linear_109_2312)" stroke-width="2.93" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M192.471 963.442C193.249 903.35 194.094 842.695 209.765 783.402C225.435 724.109 257.344 665.456 310.798 624.247C366.612 581.213 441.769 559.947 493.897 513.581C547.319 466.067 568.48 399.809 602.43 340.899C615.095 318.918 632.65 295.681 659.845 289.207C705.237 278.4 738.669 318.673 772.663 344.272C848.816 401.618 986.126 384.168 1048.33 309.238C1068.85 284.522 1081.33 255.826 1098.99 229.604C1116.65 203.37 1141.9 178.233 1175.38 170.028C1232.67 156 1289.75 196.025 1347.49 183.83C1395.32 173.721 1425.31 132.298 1464.75 105.798" stroke="url(#paint35_linear_109_2312)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_109_2312" x1="49.414" y1="550.056" x2="1624.92" y2="257.065" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint1_linear_109_2312" x1="50.6711" y1="546.845" x2="1620.78" y2="254.858" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint2_linear_109_2312" x1="51.939" y1="543.744" x2="1616.66" y2="252.759" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint3_linear_109_2312" x1="53.2254" y1="540.742" x2="1612.56" y2="250.759" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint4_linear_109_2312" x1="54.5347" y1="537.868" x2="1608.48" y2="248.887" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint5_linear_109_2312" x1="55.8807" y1="535.13" x2="1604.43" y2="247.153" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint6_linear_109_2312" x1="57.2396" y1="532.523" x2="1600.4" y2="245.547" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint7_linear_109_2312" x1="58.6266" y1="530.062" x2="1596.4" y2="244.089" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint8_linear_109_2312" x1="60.0406" y1="527.75" x2="1592.43" y2="242.778" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint9_linear_109_2312" x1="62.3296" y1="530.087" x2="1589.32" y2="246.119" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint10_linear_109_2312" x1="65.3057" y1="536.171" x2="1586.91" y2="253.205" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint11_linear_109_2312" x1="68.2157" y1="541.9" x2="1584.43" y2="259.935" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint12_linear_109_2312" x1="71.0022" y1="546.969" x2="1581.83" y2="266.006" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint13_linear_109_2312" x1="73.7989" y1="552.035" x2="1579.23" y2="272.076" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint14_linear_109_2312" x1="76.5861" y1="557.104" x2="1576.63" y2="278.147" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint15_linear_109_2312" x1="79.373" y1="562.173" x2="1574.03" y2="284.217" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint16_linear_109_2312" x1="82.1711" y1="567.249" x2="1571.43" y2="290.298" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint17_linear_109_2312" x1="84.9587" y1="572.318" x2="1568.83" y2="296.368" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint18_linear_109_2312" x1="87.745" y1="577.387" x2="1566.23" y2="302.439" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint19_linear_109_2312" x1="90.5319" y1="582.455" x2="1563.63" y2="308.509" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint20_linear_109_2312" x1="93.3291" y1="587.522" x2="1561.03" y2="314.58" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint21_linear_109_2312" x1="96.1175" y1="592.601" x2="1558.43" y2="320.66" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint22_linear_109_2312" x1="98.9043" y1="597.669" x2="1555.83" y2="326.731" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint23_linear_109_2312" x1="101.692" y1="602.738" x2="1553.23" y2="332.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint24_linear_109_2312" x1="104.488" y1="607.805" x2="1550.63" y2="338.872" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint25_linear_109_2312" x1="107.275" y1="612.873" x2="1548.03" y2="344.942" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint26_linear_109_2312" x1="110.062" y1="617.942" x2="1545.43" y2="351.013" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint27_linear_109_2312" x1="112.851" y1="623.021" x2="1542.83" y2="357.093" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint28_linear_109_2312" x1="115.647" y1="628.087" x2="1540.23" y2="363.164" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint29_linear_109_2312" x1="118.435" y1="633.156" x2="1537.63" y2="369.234" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint30_linear_109_2312" x1="121.221" y1="638.225" x2="1535.03" y2="375.305" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint31_linear_109_2312" x1="124.019" y1="643.291" x2="1532.43" y2="381.375" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint32_linear_109_2312" x1="126.805" y1="648.36" x2="1529.83" y2="387.446" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint33_linear_109_2312" x1="129.594" y1="653.439" x2="1527.23" y2="393.526" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint34_linear_109_2312" x1="132.381" y1="658.507" x2="1524.63" y2="399.597" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <linearGradient id="paint35_linear_109_2312" x1="135.178" y1="663.574" x2="1522.03" y2="405.667" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A8BCCB"/>
          <stop offset="0.15" stop-color="#70AFB3"/>
          <stop offset="0.31" stop-color="#334656"/>
          <stop offset="0.38" stop-color="#28273D"/>
          <stop offset="0.62" stop-color="#044345"/>
          <stop offset="0.69" stop-color="#378A87"/>
          <stop offset="0.85" stop-color="#A9D1DB"/>
          <stop offset="1" stop-color="#0C0C21"/>
        </linearGradient>
        <clipPath id="clip0_109_2312">
          <rect width="1608" height="694" fill="white" transform="translate(0.109375 294.845) rotate(-10.5347)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default WaveDashboard;
