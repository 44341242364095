import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Avatar,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { PlayArrow, Pause } from "@mui/icons-material";
import { formateDate } from "../../../utils";
import { MessagesTypes } from "../../../data/messages";

interface MessageProps {
  message: MessagesTypes;
  chatUserDetails: any;
  isFromMe: boolean;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  onOpenForward: (message: MessagesTypes) => void;
  isChannel: boolean;
  onDeleteImage: (messageId: string | number, imageId: string | number) => void;
}

const Message = ({ message, chatUserDetails, isFromMe }: MessageProps) => {
  const { t } = useTranslation();
  const date = formateDate(message.time, "hh:mmaaa");
  const [preview, setPreview] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const openPreview = (link: string) => {
    setPreview(link);
  };

  const closePreview = () => {
    setPreview(null);
  };

  const toggleAudioPlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleAudioTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleAudioLoaded = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration || 0);
    }
  };

  const formatTime = (time: number) => {
    if (isNaN(time) || !isFinite(time)) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const renderAttachments = () => {
    if (!message.attachments) return null;

    return message.attachments.map((attachment, index) => {
      if (!attachment.name || !attachment.downloadLink) {
        console.warn(t("invalid_attachment"), attachment);
        return null;
      }

      const fileType = attachment.name.split(".").pop()?.toLowerCase();

      if (
        ["mp3", "wav", "ogg", "webm"].includes(fileType as string) ||
        attachment.name.toLowerCase().includes("audio record")
      ) {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              padding: "8px 16px",
              width: "100%",
              maxWidth: "400px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              backgroundColor: "transparent",
            }}
          >
            <IconButton
              onClick={toggleAudioPlay}
              sx={{
                color: isFromMe ? "primary.contrastText" : "text.primary",
              }}
            >
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
            <Box flex="1" display="flex" flexDirection="column" gap="4px">
              <Box
                sx={{
                  height: "6px",
                  backgroundColor: isFromMe ? "primary.dark" : "grey.400",
                  position: "relative",
                  borderRadius: "3px",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: `${(currentTime / duration) * 100 || 0}%`,
                    backgroundColor: isFromMe
                      ? "primary.contrastText"
                      : "grey.700",
                    transition: "width 0.1s linear",
                  }}
                />
              </Box>
              <Typography
                variant="caption"
                sx={{
                  color: isFromMe ? "primary.contrastText" : "text.secondary",
                  fontSize: "12px",
                }}
              >
                {formatTime(currentTime)} / {formatTime(duration)}
              </Typography>
            </Box>
            <audio
              ref={audioRef}
              src={attachment.downloadLink}
              onTimeUpdate={handleAudioTimeUpdate}
              onLoadedMetadata={handleAudioLoaded}
              onEnded={() => setIsPlaying(false)}
            />
          </Box>
        );
      }

      // Render images
      if (["png", "jpg", "jpeg"].includes(fileType as string)) {
        return (
          <Box key={index} mt={1}>
            <img
              src={attachment.downloadLink}
              alt="Attachment"
              style={{
                maxWidth: "70%",
                borderRadius: "5px",
                marginTop: "1px",
              }}
              onClick={() => openPreview(attachment.downloadLink)}
            />
            {attachment.caption && (
              <Typography variant="body2" mt={1} color="textSecondary">
                {attachment.caption}
              </Typography>
            )}
          </Box>
        );
      }

      // Render PDFs
      if (fileType === "pdf") {
        return (
          <Box key={index} mt={1}>
            <Typography variant="body2" color="textPrimary">
              <a
                href={attachment.downloadLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                {attachment.name}
              </a>
            </Typography>
          </Box>
        );
      }

      return null;
    });
  };

  return (
    <Box
      display="flex"
      justifyContent={isFromMe ? "flex-end" : "flex-start"}
      alignItems="flex-end"
      sx={{ mb: 2 }}
    >
      {!isFromMe && (
        <Avatar
          src={chatUserDetails?.profileImage || undefined}
          alt={chatUserDetails?.name || "User"}
          sx={{ mr: 1, width: 40, height: 40 }}
        />
      )}

      <Box
        sx={{
          maxWidth: "60%",
          minWidth: "120px",
          padding: "12px 16px 30px",
          borderRadius: isFromMe ? "16px 16px 0 16px" : "16px 16px 16px 0",
          backgroundColor: isFromMe ? "primary.main" : "grey.200",
          color: isFromMe ? "primary.contrastText" : "text.primary",
          position: "relative",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        {message.text && (
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              lineHeight: "1.8",
            }}
          >
            {message.text}
          </Typography>
        )}

        {renderAttachments()}

        <Typography
          variant="caption"
          sx={{
            position: "absolute",
            bottom: "8px",
            right: "12px",
            fontSize: "12px",
            color: isFromMe ? "primary.contrastText" : "grey.600",
          }}
        >
          {date}
        </Typography>
      </Box>

      {isFromMe && (
        <Avatar
          src={chatUserDetails?.profileImage || undefined}
          alt="You"
          sx={{ ml: 1, width: 40, height: 40 }}
        />
      )}

      <Dialog open={Boolean(preview)} onClose={closePreview} fullWidth>
        <DialogContent>
          {preview && (
            <img
              src={preview}
              alt="Preview"
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Message;
