import React from "react";
import { Navigate } from "react-router-dom";

import { useProfile } from "../hooks";

const AuthProtected = (props: any) => {
  const { userProfile, loading } = useProfile();

  if (loading) {
    return <div>Loading...</div>; //TODO: REFACTOR
  }

  /*
    Commented out for front-end adjustments
    redirect is un-auth access protected routes via url
  */
  // if (!userProfile) {
  //   return <Navigate to="/auth-login" />;
  // }

  return <>{props.children}</>;
};

export { AuthProtected };
