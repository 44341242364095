import React, { useState } from "react";
import {
  Box,
  FormControl,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import EndButtons from "./EndButtons";
import InputSection from "./InputSection";
import StartButtons from "./StartButtons";
import AudioRecorder from "./AudioRecorder";
import EmojiPicker from "./EmojiPicker";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton"
import { useTranslation } from "react-i18next";

// interface
import {
  MessagesTypes,
  AttachmentTypes,
  ImageTypes,
} from "../../../../data/messages";

interface IndexProps {
  onSend: (data: MessagesTypes) => void;
  onSendAudio: (audioBlob: Blob) => void;
  replyData: null | MessagesTypes | undefined;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  chatUserDetails: any;
  isChannel: boolean;
}

const Index = ({
                 onSend,
                 onSendAudio,
                 replyData,
                 onSetReplyData,
                 chatUserDetails,
                 isChannel,
               }: IndexProps) => {
  const { t } = useTranslation();
  const [isRecording, setIsRecording] = useState(false);
  const [text, setText] = useState<string>("");
  const [images, setImages] = useState<ImageTypes[] | undefined>(undefined);
  const [files, setFiles] = useState<AttachmentTypes[] | undefined>(undefined);
  const [captions, setCaptions] = useState<{ [key: number]: string }>({});
  const [preview, setPreview] = useState<string | null>(null);

  const onChangeText = (value: string) => {
    setText(value);
  };

  const onSelectFiles = (selectedFiles: Array<any>) => {
    setFiles(prev => [
      ...(prev || []),
      ...selectedFiles.map((file: any, key: number) => ({
        id: Date.now() + key,
        name: file.name,
        downloadLink: URL.createObjectURL(file),
        desc: file.size,
      })),
    ]);
  };

  const removeFile = (id: number) => {
    setFiles(prev => prev?.filter(file => file.id !== id));
    const newCaptions = { ...captions };
    delete newCaptions[id];
    setCaptions(newCaptions);
  };

  const handleCaptionChange = (id: number, value: string) => {
    setCaptions(prev => ({ ...prev, [id]: value }));
  };

  const openPreview = (link: string) => {
    setPreview(link);
  };

  const closePreview = () => {
    setPreview(null);
  };

  const onSubmit = () => {
    const data: MessagesTypes = {
      mId: Date.now(),
      text,
      time: new Date().toISOString(),
      meta: {
        receiver: chatUserDetails.id,
        sender: "You",
        sent: true,
        received: false,
        read: false,
      },
      attachments: files?.map(file => ({
        ...file,
        caption: captions[file.id] || "",
      })),
    };

    setText("");
    setFiles(undefined);
    setCaptions({});
    onSend(data);
  };


  return (
    <Box
      className="chat-input-section"
      sx={{
        position: "absolute",
        bottom: { xs: "100px", sm: "20px" },
        width: { xs: "calc(100% - 16px)", sm: "calc(100% - 400px)", md: "calc(100% - 600px)" },
        maxWidth: "950px",
        left: { xs: "8px", sm: "400px", md: "460px" },
        backgroundColor: "rgb(255,255,255)",
        zIndex: 4,
        padding: "8px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <FormControl
        component="form"
        onSubmit={(e: any) => {
          e.preventDefault();
          onSubmit();
        }}
        fullWidth
      >
        {/* Preview of files */}
        {files && files.length > 0 && (
          <Box display="flex" flexWrap="wrap" gap={2} mb={2}>
            {files.map(file => {
              const fileType = file.name.split(".").pop()?.toLowerCase();

              return (
                <Box
                  key={file.id}
                  position="relative"
                  width="120px"
                  height="120px"
                  border="1px solid #ddd"
                  borderRadius="8px"
                  overflow="hidden"
                  onClick={() => openPreview(file.downloadLink)}
                  sx={{ cursor: "pointer" }}
                >
                  {/* Dynamic rendering */}
                  {fileType === "png" ||
                  fileType === "jpg" ||
                  fileType === "jpeg" ? (
                    <img
                      src={file.downloadLink}
                      alt={file.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : fileType === "pdf" ? (
                    <Typography textAlign="center">PDF</Typography>
                  ) : fileType === "mp4" ? (
                    <video width="100%" height="100%" controls>
                      <source src={file.downloadLink} type="video/mp4" />
                    </video>
                  ) : (
                    <Typography textAlign="center">{file.name}</Typography>
                  )}

                  {/* remove button */}
                  <IconButton
                    size="small"
                    onClick={e => {
                      e.stopPropagation();
                      removeFile(file.id);
                    }}
                    sx={{
                      position: "absolute",
                      top: 4,
                      right: 4,
                      backgroundColor: "rgba(0,0,0,0.6)",
                      color: "white",
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        )}

        <Box display="flex" alignItems="center" gap={0.5}>
          {/* Emoji Picker */}
          <EmojiPicker onSelectEmoji={emoji => setText(prev => prev + emoji)} />

          {/* Start Buttons */}
          <StartButtons
            onSelectImages={onSelectFiles}
            onSelectFiles={onSelectFiles}
          />

          <Box width="100%">
            {isRecording ? (
              <AudioRecorder
                onSendAudio={onSendAudio}
                onCancel={() => setIsRecording(false)}
              />
            ) : (
              <InputSection
                value={text}
                onChange={onChangeText}
                onSubmit={onSubmit}
              />
            )}
          </Box>

          <EndButtons
            onSubmit={onSubmit}
            isTextEmpty={text.trim() === ""}
            isRecording={isRecording}
            toggleRecording={() => setIsRecording(!isRecording)}
            hasAttachments={Boolean(files?.length || images?.length)}
          />
        </Box>
      </FormControl>

      {/* View mode */}
      <Dialog open={Boolean(preview)} onClose={closePreview} fullWidth>
        <DialogContent>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={closePreview}>
              <CloseIcon />
            </IconButton>
          </Box>
          {preview && (
            <img
              src={preview}
              alt="Preview"
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Index;
