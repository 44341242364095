import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes, ContactTypes, ConversationTypes, ImageTypes } from "../../../data";
import Cookies from "js-cookie";
import { URLS } from "../../../constants";

interface IndexProps {
  isChannel: boolean;
  contact?: ContactTypes;
  conversation: ConversationTypes;
}

const Index = ({ isChannel, contact, conversation }: IndexProps) => {
  const { userProfile } = useProfile();
  const [messages, setMessages] = useState<MessagesTypes[]>([]);
  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();
  const ws = useRef<WebSocket | null>(null);

  const fetchMessages = async (chatId: string | number) => {
    const apiKey = Cookies.get("api-key");
    if (!apiKey) {
      console.error("API Key não encontrada");
      return;
    }

    try {
      const response = await fetch(`${URLS.CLIENT_URL}/api/get-messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          APIKEY: apiKey,
        },
        body: JSON.stringify({ phone: chatId }),
      });

      if (!response.ok) {
        throw new Error(`Erro: ${response.status}`);
      }

      const data = await response.json();
      console.log("Mensagens recebidas da API:", data);

      if (data.messages) {
        const mappedMessages = data.messages.map((msg: any) => {
          const attachments = [];

          if (msg.hasMedia && msg.media.url) {
            attachments.push({
              id: Date.now(),
              name: "imagem.jpg",
              downloadLink: `data:image/jpeg;base64,${msg.media.url}`,
              desc: "Arquivo recebido do backend",
            });
          }

          return {
            mId: msg.id,
            text: msg.body,
            time: new Date(msg.timestamp * 1000).toLocaleString(),
            image: [],
            attachments,
            meta: {
              sender: msg.fromMe ? "You" : contact?.id,
              receiver: msg.fromMe ? contact?.id : "You",
              sent: msg.fromMe,
              received: !msg.fromMe,
              read: msg.fromMe,
            },
            isFromMe: msg.fromMe,
            replyOf: msg.shouldReply ? { text: msg.body, id: msg.id } : undefined,
          };
        });
        setMessages(mappedMessages);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error("Erro ao buscar as mensagens:", error);
    }
  };

  const connectWebSocket = () => {
    if (!contact?.id) return;

    const company = Cookies.get("companyName");
    const phone =     contact.id;
    const wsURL = `${URLS.WEBSOCKET_URL}/api/ws/api_official/messages?company=${company}&phone=${phone}`;

    console.log("Conectando ao WebSocket:", wsURL);

    ws.current = new WebSocket(wsURL);

    ws.current.onopen = () => {
      console.log("Conexão WebSocket estabelecida");
    };

    ws.current.onmessage = (event) => {
      console.log("Mensagem recebida do WebSocket:", event.data);
      try {
        const parsedMessage = JSON.parse(event.data);

        const attachments = [];
        if (parsedMessage.hasMedia && parsedMessage.media?.url) {
          attachments.push({
            id: Date.now(),
            name: "imagem.jpg",
            downloadLink: `data:image/jpeg;base64,${parsedMessage.media.url}`,
            desc: "Recebido via WS",
          });
        }

        const newMessage: MessagesTypes = {
          mId: parsedMessage.id,
          text: parsedMessage.body,
          time: new Date().toLocaleString(),
          attachments,
          meta: {
            sender: parsedMessage.fromMe ? "You" : contact?.id,
            receiver: parsedMessage.fromMe ? contact.id : "You",
            sent: parsedMessage.fromMe,
            received: !parsedMessage.fromMe,
            read: true,
          },
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
      } catch (error) {
        console.error("Erro ao processar mensagem WebSocket:", error);
      }
    };

    ws.current.onclose = () => {
      console.log("Conexão WebSocket fechada");
    };

    ws.current.onerror = (error: any) => {
      console.error("Erro no WebSocket:", error);
    };
  };

  const disconnectWebSocket = () => {
    if (ws.current) {
      ws.current.close();
    }
  };

  useEffect(() => {
    if (contact?.id) {
      fetchMessages(contact.id);
      connectWebSocket();
    }

    return () => {
      disconnectWebSocket();
    };
  }, [contact]);

  const prepareBackendPayload = (
    message: MessagesTypes,
    contactId: string | number
  ) => {
    return {
      clientID: "556181405831",
      messageID: String(contactId),
      answeringTo: !!message.replyOf,
      chatId: "556181405831",
      channel: "API_OFFICIAL",
      hasMedia: (message.image && message.image.length > 0) || (message.attachments && message.attachments.length > 0),
      originsIA: true,
      shouldConvertToSpeech: false,
      shouldReply: !!message.replyOf,
      session: "3d56a3ed-a4cb-4b9a-a9f1-af10240878bd",
      data: {
        message: message.text || "",
        timestamp: new Date().toISOString(),
      },
      media: (message.image || []).map((image) => ({
        base64Data: image,
        filename: "image.jpg",
        mimetype: "image/jpeg",
        url: "",
      })).concat(
        (message.image || []).map((image) => ({
          base64Data: image,
          filename: "image.jpg",
          mimetype: "image/jpeg",
          url: "",
        }))
      ),
    };
  };

  const sendToBackend = async (payload: any) => {
    try {
      const apiKey = Cookies.get("api-key");
      if (!apiKey) {
        console.error("API Key não encontrada");
        return;
      }

      const response = await fetch(`${URLS.CLIENT_URL}/api/receive-message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          APIKEY: apiKey,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Falha ao enviar mensagem: ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Mensagem enviada ao backend com sucesso:", result);
    } catch (error) {
      console.error("Erro ao enviar mensagem ao backend:", error);
    }
  };

  const onSend = async (data: MessagesTypes) => {
    if (!contact?.id) {
      console.error("ID do contato não definido");
      return;
    }

    const newMessage: MessagesTypes = {
      mId: new Date().getTime(),
      text: data.text || "",
      time: new Date().toISOString(),
      image: data.image || [],
      attachments: data.attachments || [],
      meta: {
        receiver: contact.id as string | number,
        sender: "You",
        sent: true,
        received: false,
        read: true,
      },
      replyOf: replyData || undefined,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);

    const backendPayload: any = {
      clientID: "556181405831",
      messageID: String(contact.id),
      answeringTo: !!newMessage.replyOf,
      chatId: String(contact.id),
      channel: "API_OFFICIAL",
      hasMedia: false,
      originsIA: true,
      shouldConvertToSpeech: false,
      shouldReply: !!newMessage.replyOf,
      session: "3d56a3ed-a4cb-4b9a-a9f1-af10240878bd",
      data: {
        message: data.text || "",
        timestamp: new Date().toISOString(),
      },
      media: [],
    };

    function isImage(filename: string): boolean {
      const ext = filename.toLowerCase().split(".").pop();
      return ["jpg", "jpeg", "png", "gif", "webp"].includes(ext ?? "");
    }

    if (data.attachments && data.attachments.length > 0) {
      const base64Images = await Promise.all(
        data.attachments
          .filter(att => isImage(att.name))
          .map(async (att) => {
            const file = await fetch(att.downloadLink).then(r => r.blob());
            const base64Data = await convertToBase64(file);
            return {
              base64Data: base64Data.split(",")[1],
              filename: att.name,
              mimetype: file.type || "image/jpeg",
            };
          })
      );
      if (base64Images.length) {
        backendPayload.media = base64Images;
        backendPayload.hasMedia = true;
      }
    }

    console.log("Payload enviado ao backend:", backendPayload);

    await sendToBackend(backendPayload);
  };

  const onSendAudio = async (audioBlob: Blob) => {
    if (!contact?.id) {
      console.error("ID do contato não definido");
      return;
    }

    const audioMessage: MessagesTypes = {
      mId: new Date().getTime(),
      text: "Audio Message",
      time: new Date().toISOString(),
      meta: {
        receiver: contact.id as string | number,
        sender: "You",
        sent: true,
        received: false,
        read: true,
      },
      attachments: [
        {
          id: new Date().getTime(),
          downloadLink: URL.createObjectURL(audioBlob),
          name: `Audio Recording ${new Date().toLocaleString()}`,
          desc: `${audioBlob.size} bytes`,
        },
      ],
    };

    setMessages((prevMessages) => [...prevMessages, audioMessage]);

    const backendPayload = prepareBackendPayload(audioMessage, contact.id);
    await sendToBackend(backendPayload);
  };

  function convertToBase64(file: Blob | File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <UserHead
        chatUserDetails={contact}
        pinnedTabs={[]}
        onOpenUserDetails={() => {}}
        onDelete={() => {}}
        isChannel={isChannel}
        onToggleArchive={() => {}}
      />

      <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
        <Box
          flexGrow={1}
          sx={{
            maxHeight: "calc(100vh - 150px)",
            zIndex: 1,
          }}
        >
          <Conversation
            chatUserConversations={{
              conversationId: String(contact?.id),
              messages,
            }}
            chatUserDetails={contact}
            onDelete={() => {}}
            onSetReplyData={setReplyData}
            isChannel={isChannel}
          />
        </Box>

        <ChatInputSection
          onSend={onSend}
          onSendAudio={onSendAudio}
          replyData={replyData}
          onSetReplyData={setReplyData}
          chatUserDetails={contact}
          isChannel={isChannel}
        />
      </Box>
    </Box>
  );
};

export default Index;
